/* Index.css */

@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Additional global styles */
body {
  font-family: 'PT Serif', serif;
}
