:root {
  --rhap_theme-color: #fff; /* Default: #868686 */
  --rhap_background-color: rgba(0,0,0,0);; /* Default: #fff */
  --rhap_bar-color: #fff; /* Default: #dddddd */
  --rhap_time-color: #ffffff; /* Default: #333 */
  --rhap_font-family: inherit; /* Default: inherit */
}

@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');


.site-container {
  display: flex;
  flex-direction: column;
/* min-height: 100vh; */
}

body {
  /* font-family: 'Roboto', sans-serif; */
  background: linear-gradient(0deg, #000010, #0250f7);
  /* height: 100vh; */
  width: 100%;
  /* overflow: hidden; */
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  body {
    margin-top: .7rem;
    top:0;
    left:0;
    right:0;
    bottom:0;
    overflow-y: auto;
  }

  .modal {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
}



#svg{
  filter: drop-shadow(2px 2px 4px rgba(0, 2, 2, 0.2)); /* horizontal offset, vertical offset, blur radius, color */
}

/* .faCircleDown svg{ Help Me!!
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
} */

.rhap_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  line-height: 1;
  font-family: var(--rhap_font-family);
  width: 100%;
  padding: 10px 15px;
  background-color: var(--rhap_background-color);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.rhap_container svg{
  color: #fff;
}

.rhap_progress-indicator{
  background:#fff;
}

.rhap_volume-indicator{
  background: #fff;
}

.rhap_container:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_main-controls-button {
  margin: 0 0px;
  color: #fff;
  font-size: 35px;
  width: 35px;
  height: 35px;
}

.rhap_time {
  color: #fff;
  font-size: 18px;
  user-select: none;
  -webkit-user-select: none;
}

/** resolve word wrap on small screens */
.now-playing{
  color: #b3ff00;
  background-color: #000;
  padding: .3em .5em;
  border-radius: 5px;
  margin-right: 5px;
  
}

.rhap_horizontal {
  flex-direction: row;
}

.spacer {
  display: flex;
  text-align: center; 
  color: #fff;
}

/** header starts here */

/* .revealed-player  {
  background-color: #ff6600;
  border-radius: 10px;
  margin: 12px;
  position: relative;
  display: flex;
  align-items: center;
  max-height: 55px;
  width: auto;
  min-width: 412px;
  overflow-y: hidden;
} */

.AudioPlayer_wrapper {
  background-color: #ff6600;
  border-radius: 10px;
  margin: 12px;
  position: relative;
  display: flex;
  align-items: center;
  max-height: 55px;
  width: auto;
  min-width: 412px;
  overflow-y: hidden;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* horizontal offset, vertical offset, blur radius, color */

  /* transform: translateY(-200px); */
}

/* Header styling */

.header {
  width: 100vw;
  position: relative;
  display: flex;
  align-content: flex-start;
  align-items: top;
  /* padding:  10px; */
  background-color: rgba(0,0,0,0); /* Example background color for the header */
}

g {
  /* This is the white area of the Logo svg, here we are setting the default value #fff */
  fill:#fff;
}

.Logo_wrapper {    
  /* Styles for the Logo wrapper */
  margin-left: 10px;
  display: flex;
  align-items: center;
  position: relative; /* Ensure the wrapper is positioned relative */
  width: 150px;
  min-width: 150px; /* Make the wrapper take up 100% width of its parent */
  
  height: auto; /* Allow the height to adjust based on the content */
}
Logo .logo-color{
  fill:#10308b;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
} 

.Logo {
  max-width: 100%; /* Ensure the logo never exceeds the width of its parent */
  height: auto; /* Maintain the aspect ratio of the logo */
  
}

.arrow{
  position: relative;
  margin-left: 10px;
  top: 0;
}

.rotate-180 {
  transform: rotate(180deg);
  /* Add any other styles you need for the rotated state */
}

.ConnectButton_wrapper {
  background-color: #ff6600;
  border-radius: 10px;
  position:absolute;
  top: 24px;
  right: 35px;
  padding: 0 2%;
  height: 55px;
  width: wrap;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .ConnectButton_wrapper {
    background-color: #ff6600;
    border-radius: 10px;
    position:absolute;
    top: 12%;
    right: 35px;
    padding: 0 2%;
    height: 55px;
    width: wrap;
    display: flex;
    align-items: center;
  }

}

.ConnectButton {
  color: #fff;
  font-weight: 900;
}

/** Content Section - Styling should be inherited by children */
.content {
  flex: 1; /* This allows the content to expand and push the footer to the bottom */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x:hidden;
}

/* JellyAbout */
/** this is a general about-page rule set */
.about-page {
  display: flex;
  justify-content: center; /* Evenly distribute cards */
  max-width: 63vw; /* Maximum width of 60% of viewport width */
  margin: 0 auto; /* Center the gallery */
  flex-direction: row; /* Arrange children in a row */
}

.jellys-about {
  flex: 1; /* Allow text to grow and shrink */
  padding: 0 20px; /* Add padding for spacing between image and text */
}

.about-key-frame{
  display: flex;
}

/* Container for the entire gallery */
.page-gallery {
display: flex;
justify-content: center; /* Evenly distribute cards */
max-width: 63vw; /* Maximum width of 60% of viewport width */
margin: 0 auto; /* Center the gallery */
}

/* Individual card styles */
.card {
display: flex;
flex-direction: column; /* Display card contents in a column */
flex: 1; /* Allow cards to grow and shrink */
max-width: calc(33.33% - 20px); /* Calculate maximum width for 3 cards with some spacing */
margin: 5%; /* Space between cards */
}

.card-image {
width: 100%; 
height: auto; /* Example height */
/* Add any other styles for the image */
}

.card-footer {
  width: 100%;
/* let add the image in as a background */
}

.under-shadows{
width: 100%;
}

/** Footer Styling*/


a {
  text-decoration: none;
}

/* unvisited link */
a:link {
  color:  #ffffff;
}

/* visited link */
a:visited {
  color: #ccc9c9;
}


.social ul {
  list-style: none;
  padding: 10px;
  display: flex;
  gap: 20px; /* This controls the space between each link */
}

.nav ul{
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px; /* This controls the space between each link */
}

.nav li, .nav li a, .social li {
  cursor: pointer;
  transition: color 0.3s ease;
}

.nav li a:hover {
  color: #ff7904;
}

/* Social icons */
.social li {
  width: 24px; /* Icon size */
  height: 24px; /* Icon size */
  /* Add background images for icons as needed */
} 


/* Existing CSS for Footer */
.footer {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 10px;
background-color: rgba(0, 0, 0, 0);
color: #ffffff;
}

.nav, .social {
display: flex;
justify-content: center;
gap: 30px;
}

.nav ul {
list-style: none;
padding: 0;
display: flex;
gap: 20px;
}

.nav ul li {
font-weight: 500; /* Apply bold font weight to list items */
}

@media (max-width: 768px) {
  .nav ul li {
    font-weight: 400;
    font-size: small; /* Apply bold font weight to list items */
    }
}
.nav li, .social li {
cursor: pointer;
transition: color 0.3s ease;
}

.nav li:hover, .social li:hover {
color: #ff6600;
}

/* Updated CSS for Social Icons */
.social li a {
display: block;
width: 32px;
height: 32px;
background-size: cover;
background-repeat: no-repeat;
text-indent: -9999px;
margin-right: 0px; /* Adjust spacing between icons */
transition: all .3s ease-in-out; 
}

.social li a:hover { 
transform: scale(1.2); 
}

/* Social icon backgrounds */
.social li.warpcast a {
background-image: url('/src/images/social-icon-waycaster.png'); /* Replace with your SVG icon path */
}

.social li.telegram a {
background-image: url('/src/images/social-icon-telegram.png'); /* Replace with your SVG icon path */
}

.social li.pinterest a {
background-image: url('/src/images/social-icon-pinterest.png'); /* Replace with your SVG icon path */
}

.social li.twitter a {
background-image: url('/src/images/social-icon-twixter.png'); /* Replace with your SVG icon path */
}

/* In your CSS file, e.g., App.css or a separate ExploreSidebar.css */

.explore-sidebar {
  width: 250px; /* Adjust width as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 100vh; /* Ensure it doesn't exceed viewport height */
}

/* Add this to your CSS file */
.explore-sidebar::-webkit-scrollbar {
  width: 12px;
}

.explore-sidebar::-webkit-scrollbar-track {
  background: #000; /* Black background */
}

.explore-sidebar::-webkit-scrollbar-thumb {
  background-color: #333; /* Dark gray thumb */
  border-radius: 10px;
  border: 3px solid #000; /* Padding around thumb */
}
